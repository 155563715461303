<template>
    <div class="tools">
        <div class="main">
            <div class="tools_1">
                <textarea rows="30" cols="130" v-model="toolsInput1"></textarea>
                <div class="button-choice">
                    <div class="button green" @click="getJsonWord()">
                     转换
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'tools',
  components: {},
  data () {
    return {
      toolsInput1: ''
    }
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    getJsonWord () {
      const lines = this.toolsInput1.split('\n')
      var result = {}
      var wordEn
      var en
      var value = ''
      var notesStart = false
      var notes = ''
      lines.forEach(line => {
        if (line.trim() !== '') {
          if (line.indexOf(' [') > -1) {
            if (wordEn) {
              result[wordEn] = { value: value.substring(0, value.length - 1), en, notes: notes.substring(0, notes.length - 1) }
              notesStart = false
              value = ''
              notes = ''
            }
            const w = line.split(' [')
            wordEn = w[0]
            en = '英 [' + w[1]
          } else {
            if (!notesStart && (line.indexOf('短语：') > -1 || line.indexOf('例：') > -1)) {
              notesStart = true
            }
            if (notesStart) {
              notes += line + '\n'
            } else {
              value += line + '\n'
            }
          }
        }
      })
      if (wordEn) {
        result[wordEn] = { value: value.substring(0, value.length - 1), en, notes: notes.substring(0, notes.length - 1) }
      }
      console.log(JSON.stringify(result, null, 2))
    }
  }
}
</script>

<style scoped>
.tools {
  width: 100vw;
  height: 100vh;
  background: #F0F0F0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.main {
  height: fit-content;
  padding: 70px 30px;
  box-sizing: border-box;
  max-width: 1000px;
  margin: 0 auto;
}

.button-choice {
  width: 100%;
  height: 50px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
  box-sizing: border-box;
}

.button {
  width: 23%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  transition: all 0.4s ease;
}

.green {
  border: solid 2px #90c0ac;
  color: #90c0ac;
}

</style>
